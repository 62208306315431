<template>
  <div class="list-info">
    <top-bar :title="'公告'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入公告标题" class="search-content"
        @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <!-- <div class="addBtn" @click="$router.push('/notice-update')">
      <img src="@/assets/img/add.png" alt="">
    </div> -->
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
        v-if="dataList.length > 0" offset="10">
        <div v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
          <div class="divider"></div>
          <div class="content-info">
            <div class="content-top">
              <div class="top-left">
                <div class="title">{{item.title}}</div>
              </div>
              <div class="top-right" v-if="item.surveyObject == 0">
                <div v-if="item.userRead == 1" class="readType">已读</div>
                <div v-if="item.userRead == 0" class="readType noRead">未读</div>
                <!-- <van-image v-if="item.userRead == 1" :src="require('@/assets/img/yidu.png')"></van-image>
                  <van-image v-else :src="require('@/assets/img/weidu.png')"></van-image> -->
              </div>
            </div>
            <div class="mid-content">
              <div class="content">{{item.content}}</div>
            </div>
            <div class="content-bottom">
              <!-- <div class="bottom-right"><div class="isReply">已回复</div></div> -->
              <div class="bottom-right">已读：{{item.readNum}}人</div>
              <div class="bottom-left">{{item.createTime}}
                <div class="bottom-edit"
                  v-if=" item.createClient == 1 && orgId == item.createOrgId"
                  @click.stop="editHandle(item.id,true)">编辑</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import { mapMutations } from 'vuex'
export default {
  components: {
    topBar
  },
  data() {
    return {
      orgId: this.$orgId,
      pullLoading: false,
      totalCount: '',
      searchValue: '',
      loading: false,
      finished: false,
      limit: 10,
      page: 0,
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getInfo(id) {
      this.$store.commit('setNoticeId', id)
      this.$router.push('/notice-info')
    },
    getDataList() {
      this.page++
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/notice/newList'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          title: this.searchValue,
          type: '',
          orgid: this.$orgId,
          logInOrgId: this.$orgId,
          orgUserId: this.$globalData.userInfo.userId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.pullLoading = false
          console.log(data);
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          data.page.list.map(item => {
            return item.content = item.content.replace(/<[^>]+>/g, '')
          })
          this.dataList = this.dataList.concat(data.page.list)
          this.totalCount = data.page.totalCount
          // 加载状态结束
          this.dataList.sort(function (a, b) { return a.createTime < b.createTime ? 1 : -1 })
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => { this.$toast.clear() })
      // 数据全部加载完成
    },
    onPullDownRefresh(e) {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    editHandle(id,readonly) {
      this.$router.push({path: '/notice-update',query: {id,readonly}})
    }
  },
  created() {
    this.getDataList()
  }
}
</script>
<style scoped>
.search {
  padding-bottom: 20px !important;
}
</style>

<style lang="scss" scoped>
.isReply {
  background: rgba(242, 76, 72, 0.1200);
  border-radius: 8px;
  padding: 8px 32px;
  line-height: 34px;
  color: #F24C48;
}

.list-info {
  .filterBar {
    padding-top: 24px;
    height: 128px;
    // padding: 24px 0;
  }

  .party-search {
    position: relative;
    margin: 0 auto;
    width: 690px;
    height: 64px;
    background: #F5F5F5;
    border-radius: 20px;

    .search-content {
      padding-top: 16px;
      color: #666;
      padding-left: 76px !important;
      display: block !important;
      width: 480px !important;
      margin: 0 122px 0 30px !important;
      background-color: transparent;
      border: none;
    }

    .search-content::placeholder {
      color: #CCCCCC;
    }

    .clear-btn {
      position: absolute;
      top: 16px;
      right: 135px;
    }

    .search-divider {
      width: 2px;
      height: 32px;
      background: #DBDBDB;
      border-radius: 2px;
      position: absolute;
      left: 80px;
      top: 16px;
    }

    .search-icon {
      position: absolute;
      top: 16px;
      left: 24px;
      width: 32px;
      height: 32px;
    }

    .search-btn {
      font-size: 24px;
      border-radius: 20px;
      text-align: center;
      position: absolute;
      right: 4px;
      top: 4px;
      width: 112px;
      height: 56px;
      line-height: 56px;
      background-color: #F24C48;
      color: #fff;
    }
  }

  .list-title {
    width: 750px;
    height: 88px;
    background: rgba(255, 255, 255, 0);
    font-size: 32px;
    font-family: PingFang-Bold;
    font-weight: 500;
    color: #333333;
    text-align: left;
    padding: 0 30px;
  }

  .divider {
    width: 100%;
    height: 24px;
  }

  .content-info {
    background: #fff;
    padding: 0 30px;

    .content-top {
      font-size: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0 24px;

      //   border-bottom: 1px solid rgba(0, 0, 0, 0.1000);
      img {
        width: 140px;
        height: 140px;
        border-radius: 20px;
        margin-right: 24px;
      }

      .top-left {
        .title {
          color: #333;
          line-height: 40px;
          font-size: 28px;
          font-family: PingFang-Bold;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }

      }

      .top-right {
        .readType {
          width: 76px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          color: #fff;
          text-align: center;
          background: linear-gradient(90deg, #B6B6B6 0%, #939393 100%);
          border-radius: 20px 4px 12px 4px;
        }
        .noRead {
          background: linear-gradient(315deg, #4581F8 0%, #45CEF8 100%);
        }
      }
    }

    .mid-content {
      height: 116px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1000);

      .content {
        color: #666;
        ;
        margin-top: 12px;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .content-bottom {
      display: flex;
      justify-content: space-between;
      padding: 24px 0;
      color: #999;
      align-items: center;

      .bottom-left {
        display: flex;
        align-items: center;

        .bottom-edit {
          width: 104px;
          height: 56px;
          background: #F24C48;
          border-radius: 8px;
          font-size: 24px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 56px;
          text-align: center;
          margin-left: 24px;
        }
      }

    }
  }
}
</style>